@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-style: 400;
  color: #000;
  font-size: 16px;
  background-color: #f4f6fd; /* #0063ec; */
  margin: 0;
}
@media screen and (max-width: 576px) {
  body {
    background-color: #fff;
  }
}

.fc-navigation {
  position: relative;
  background-color: #0063ec;
}
.fc-navigation .fc-logo-wrapper,
.fc-navigation .fc-nav-wrapper {
  display: inline-block;
  height: 48px;  
}
.fc-logo-wrapper {
  width: 16%;
  padding-left: 24px;
  box-sizing: border-box;
}
.fc-logo-wrapper img {
  width: 100%;
  max-width: 56px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.fc-big-puppet-wrapper img {
  display: block;
  max-width: 160px;
  margin: auto;
}
.fc-nav-icon img {
  max-width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right:24px;
}
.fc-nav-wrapper {
  width: 84%;
  padding-right: 24px;
  box-sizing: border-box;
}
.fc-nav-wrapper .fc-lg-menu {
  text-align: right;
}
.fc-nav-wrapper .fc-lg-menu > div ul {
  margin: 0;
}
.fc-nav-wrapper .fc-sm-menu > div ul {
  list-style: none;
  background-color: #eee;
  padding: 0;
  margin: 0;
}
.fc-nav-wrapper .fc-sm-menu > div ul li {
  padding: 16px 24px; 
  text-align: left;
  border-top: #aaa; 
}
.fc-footer {
  border-bottom: 1px solid #0063ed;
}
.fc-footer .fc-lang-sel-button {
  background-color: transparent;
  border: none;
  padding: 16px 24px; 
  text-align: left;
  text-transform: uppercase;
}
.fc-nav-wrapper .fc-menu ul li.fc-lang-sel-button img {
  width: 100%;
  max-width: 32px;
  display: block;
  margin: auto;
}

.fc-nav-wrapper .fc-lg-menu > div ul li {
  display: inline;
  padding-left: 24px; 
}
.fc-nav-wrapper div.fc-sm-menu > div:not([role="button"]) {
  position: absolute;
  left: 0;
  right: 0;
  top: 53px;
}

@media screen and (max-width: 576px) {
}

/* Map section */
.fc-button-wrapper {
}

.fc-list-item {
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  transition: all ease-out 150ms;
}
.fc-list-item .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.fc-list-item input[type="text"] {
  background: transparent;
}
.fc-list-item input[type="text"]:focus {
  cursor: text;
}
.fc-list-item .actions {
  transition: all 200ms ease-in;
  padding-right: 20px;
}
.fc-list-item .actions a,
.fc-list-item .actions span[onClick] {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  color: #22a;
}
.fc-list-item .actions a:hover,
.fc-list-item .actions span[onClick]:hover {
  color: #33d;
}
.fc-list-item .actions a:active,
.fc-list-item .actions span[onClick]:active {
  color: #66a;
}
.fc-list-item .actions [class^="icon-"] {
  font-size: 16px;
  line-height: 24px;
  line-height: 3rem;
  text-align: center;
}
.fc-list-item:hover {
  /*background-image: linear-gradient(to bottom, #e5f9f7 0%, #f0fffd 100%);*/
}
.fc-list-item:hover .checkbox {
  cursor: pointer;
}
.fc-list-item + .fc-list-item {
  border-top: 1px solid #f0f9fb;
}
.fc-list-item.checked input[type="text"] {
  color: #ccc;
  text-decoration: line-through;
}
.fc-list-item.checked .delete-item {
  display: inline-block;
}

.fc-list-items {
  position: relative;
  background: white;
  min-height: 288px;
}
.fc-list-items .select-placeholder {
  border: none;
  width: 48px;
}
.fc-contact {
  box-sizing: border-box;
  overflow: hidden;
}
.fc-contact input {
  width: 100%;
  display: inline-block;
  border: none;
  padding: 4px;
}
.fc-contact .fc-contact__verified,
.fc-contact .fc-contact__info,
.fc-contact .fc-contact__actions {
  padding: 8px;
  display: inline-block;
  box-sizing: border-box;
}
.fc-contact .fc-contact__verified {
  width: 12%;
  text-align: center;
}
.fc-contact .fc-contact__verified img {
  width: 100%;
  max-width: 24px;
}
.fc-contact .fc-contact__info {
  width: 64%;
  text-align: left;
}
.fc-contact .fc-contact__actions {
  width: 24%;
  text-align: right;
}

.fc-list-item.fc-tata .fc-tata__title {
  background-color: #fff;
}
.fc-list-item.fc-tata .fc-tata__title h1 {
  font-size: 15px;
  line-height: 16px;
}

.fc-prod-hide {
}
.fc-hide-all {
  display: none !important;
  opacity: 0;
  height: 0;
  width: 0;
  opacity: 0;
}
.fc-page {
}
.fc-content {
  background-color: #fff; /*#f6f7f9;*/
  width: 100%;
  max-width: 576px;
  margin: 64px auto;
  color: #586475;
}
@media screen and (max-width: 576px) {
  .fc-content {
    margin: 0 auto;
  } 
}
.fc-tata-puppet {
  float: right;
  transform: translateY(-40%);
  padding-right: 40px;
}

/* COLORS */
.fc-bg-black {
    background-color: #000;
}
.fc-black {
    color: #000;
}
.fc-bg-blue {
    background-color: #0063ec;
}
.fc-blue {
    color: #0063ec;
}
.fc-bg-white {
    background-color: #fff;
}
.fc-white {
    color: #fff;
}
.fc-bg-gray-e {
    background-color: #eee;
}
.fc-gray-e {
    color: #eee;
}
.fc-bg-gray-f {
  background-color: #f6f7f9;
}
.fc-gray-f {
    color: #f6f7f9;
}
.fc-bg-gray-4 {
    background-color: #444;
}
.fc-gray-4 {
    color: #444;
}
.fc-bg-gray-7 {
    background-color: #777;
}
.fc-gray-7 {
    color: #777;
}
.fc-bg-gray-9 {
    background-color: #939394;
}
.fc-gray-9 {
    color: #939394;
}
.fc-bg-green {
    background-color: #00C956;
}
.fc-green {
    color: #00C956;
}
.fc-color-alarm-true {
  color: #FC4631;
}
.fc-color-alarm-false {
  color: #0063ed;
}
.fc-intro__w90 {
  min-width: 250px;
  width: 80%;
}
.fc-bg-lightblue {
  background-color: #F4F6FD;
}
.fc-error {
  background-color: #feb5ad; /*FC4631 with .4 alpha*/
}
.fc-bg__bordered {
  border-top: 4px solid #0063ec;
  border-bottom: 1px solid #0063ec;
}
/* FONT */
.fc-ff1 {
    font-family: Source Sans Pro;
}
.fc-ff2 {
    font-family: 'Montserrat','Open Sans',Source Sans Pro,sans-serif,Helvetica,Arial;
}
.fc-lh-10 {
    line-height: 1;
}
.fc-lh-12 {
    line-height: 1.2;
}
.fc-lh-13 {
    line-height: 1.3;
}
.fc-lh-14 {
    line-height: 1.4;
}
.fc-lh-16 {
    line-height: 1.6;
}
.fc-fs-10, .fc-fs-r10 {
    font-size: 10px;
}
.fc-fs-11, .fc-fs-r11 {
    font-size: 11px;
}
.fc-fs-12, .fc-fs-r12 {
    font-size: 12px;
}
.fc-fs-13, .fc-fs-r13 {
    font-size: 13px;
}
.fc-fs-14, .fc-fs-r14 {
    font-size: 14px;
}
.fc-fs-16, .fc-fs-r16 {
    font-size: 16px;
}
.fc-fs-20, .fc-fs-r20 {
    font-size: 20px;
}
.fc-fs-24, .fc-fs-r24 {
    font-size: 24px;
}
.fc-fs-32, .fc-fs-r32 {
    font-size: 32px;
}
.fc-fs-40, .fc-fs-r40 {
    font-size: 40px;
}
@media (max-width: 992px) {
    .fc-fs-r12 {
        font-size: 8px;
    }
    .fc-fs-r16 {
        font-size: 12px;
    }
    .fc-fs-r24 {
        font-size: 16px;
    }
    .fc-fs-r32 {
        font-size: 24px;
    }
    .fc-fs-r40 {
        font-size: 32px;
    }
}
.fc-emph1 {
    font-weight: bold;
    text-transform: uppercase;
}
.fc-emph2 {
    font-weight: bold;
}
.fc-emph3 {
    text-transform: uppercase;
}
.fc-td-u {
  text-decoration: underline;
}
/* POSITIONS */
.fc-ta-l {
    text-align: left; 
}
.fc-ta-c {
    text-align: center; 
}
.fc-ta-r {
    text-align: right; 
}
.fc-f-l {
    float: left; 
}
.fc-f-n {
    float: none; 
}
.fc-f-r {
    float: right; 
}

/* padding */
.fc-p-0 {
  padding: 0px;
}
.fc-pv-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.fc-ph-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.fc-pt-0 {
  padding-top: 0px;
}
.fc-pb-0 {
  padding-bottom: 0px;
}
.fc-pl-0 {
  padding-left: 0px;
}
.fc-pr-0 {
  padding-right: 0px;
}

.fc-p-8 {
  padding: 8px;
}
.fc-pv-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.fc-ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.fc-pt-8 {
  padding-top: 8px;
}
.fc-pb-8 {
  padding-bottom: 8px;
}
.fc-pl-8 {
  padding-left: 8px;
}
.fc-pr-8 {
  padding-right: 8px;
}

.fc-p-16 {
  padding: 16px;
}
.fc-pv-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.fc-ph-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.fc-pt-16 {
  padding-top: 16px;
}
.fc-pb-16 {
  padding-bottom: 16px;
}
.fc-pl-16 {
  padding-left: 16px;
}
.fc-pr-16 {
  padding-right: 16px;
}

.fc-p-24 {
  padding: 24px;
}
.fc-pv-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.fc-ph-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.fc-pt-24 {
  padding-top: 24px;
}
.fc-pb-24 {
  padding-bottom: 24px;
}
.fc-pl-24 {
  padding-left: 24px;
}
.fc-pr-24 {
  padding-right: 24px;
}

.fc-p-32 {
  padding: 32px;
}
.fc-pv-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.fc-ph-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.fc-pt-32 {
  padding-top: 32px;
}
.fc-pb-32 {
  padding-bottom: 32px;
}
.fc-pl-32 {
  padding-left: 32px;
}
.fc-pr-32 {
  padding-right: 32px;
}

.fc-p-40 {
  padding: 40px;
}
.fc-pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.fc-ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.fc-pt-40 {
  padding-top: 40px;
}
.fc-pb-40 {
  padding-bottom: 40px;
}
.fc-pl-40 {
  padding-left: 40px;
}
.fc-pr-40 {
  padding-right: 40px;
}

.fc-p-48 {
  padding: 48px;
}
.fc-pv-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.fc-ph-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.fc-pt-48 {
  padding-top: 48px;
}
.fc-pb-48 {
  padding-bottom: 48px;
}
.fc-pl-48 {
  padding-left: 48px;
}
.fc-pr-48 {
  padding-right: 48px;
}

.fc-p-56 {
  padding: 56px;
}
.fc-pv-56 {
  padding-top: 56px;
  padding-bottom: 56px;
}
.fc-ph-56 {
  padding-left: 56px;
  padding-right: 56px;
}
.fc-pt-56 {
  padding-top: 56px;
}
.fc-pb-56 {
  padding-bottom: 56px;
}
.fc-pl-56 {
  padding-left: 56px;
}
.fc-pr-56 {
  padding-right: 56px;
}

.fc-p-64 {
  padding: 64px;
}
.fc-pv-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}
.fc-ph-64 {
  padding-left: 64px;
  padding-right: 64px;
}
.fc-pt-64 {
  padding-top: 64px;
}
.fc-pb-64 {
  padding-bottom: 64px;
}
.fc-pl-64 {
  padding-left: 64px;
}
.fc-pr-64 {
  padding-right: 64px;
}

.fc-p-80 {
  padding: 80px;
}
.fc-pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.fc-ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}
.fc-pt-80 {
  padding-top: 80px;
}
.fc-pb-80 {
  padding-bottom: 80px;
}
.fc-pl-80 {
  padding-left: 80px;
}
.fc-pr-80 {
  padding-right: 80px;
}

.fc-p-96 {
  padding: 96px;
}
.fc-pv-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}
.fc-ph-96 {
  padding-left: 96px;
  padding-right: 96px;
}
.fc-pt-96 {
  padding-top: 96px;
}
.fc-pb-96 {
  padding-bottom: 96px;
}
.fc-pl-96 {
  padding-left: 96px;
}
.fc-pr-96 {
  padding-right: 96px;
}

.fc-p-120 {
  padding: 120px;
}
.fc-pv-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
.fc-ph-120 {
  padding-left: 120px;
  padding-right: 120px;
}
.fc-pt-120 {
  padding-top: 120px;
}
.fc-pb-120 {
  padding-bottom: 120px;
}
.fc-pl-120 {
  padding-left: 120px;
}
.fc-pr-120 {
  padding-right: 120px;
}

.fc-p-144 {
  padding: 144px;
}
.fc-pv-144 {
  padding-top: 144px;
  padding-bottom: 144px;
}
.fc-ph-144 {
  padding-left: 144px;
  padding-right: 144px;
}
.fc-pt-144 {
  padding-top: 144px;
}
.fc-pb-144 {
  padding-bottom: 144px;
}
.fc-pl-144 {
  padding-left: 144px;
}
.fc-pr-144 {
  padding-right: 144px;
}

.fc-p-160 {
  padding: 160px;
}
.fc-pv-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}
.fc-ph-160 {
  padding-left: 160px;
  padding-right: 160px;
}
.fc-pt-160 {
  padding-top: 160px;
}
.fc-pb-160 {
  padding-bottom: 160px;
}
.fc-pl-160 {
  padding-left: 160px;
}
.fc-pr-160 {
  padding-right: 160px;
}

/* margin */
.fc-m-4 {
  margin: 4px;
}
.fc-mv-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.fc-mh-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.fc-m-8 {
  margin: 8px;
}
.fc-mv-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.fc-mh-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.fc-mt-8 {
  margin-top: 8px;
}
.fc-mb-8 {
  margin-bottom: 8px;
}
.fc-ml-8 {
  margin-left: 8px;
}
.fc-mr-8 {
  margin-right: 8px;
}

.fc-m-16 {
  margin: 16px;
}
.fc-mv-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.fc-mh-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.fc-mt-16 {
  margin-top: 16px;
}
.fc-mb-16 {
  margin-bottom: 16px;
}
.fc-ml-16 {
  margin-left: 16px;
}
.fc-mr-16 {
  margin-right: 16px;
}

.fc-m-24 {
  margin: 24px;
}
.fc-mv-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.fc-mh-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.fc-mt-24 {
  margin-top: 24px;
}
.fc-mb-24 {
  margin-bottom: 24px;
}
.fc-ml-24 {
  margin-left: 24px;
}
.fc-mr-24 {
  margin-right: 24px;
}

.fc-m-32 {
  margin: 32px;
}
.fc-mv-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.fc-mh-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.fc-mt-32 {
  margin-top: 32px;
}
.fc-mb-32 {
  margin-bottom: 32px;
}
.fc-ml-32 {
  margin-left: 32px;
}
.fc-mr-32 {
  margin-right: 32px;
}

.fc-m-40 {
  margin: 40px;
}
.fc-mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.fc-mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.fc-mt-40 {
  margin-top: 40px;
}
.fc-mb-40 {
  margin-bottom: 40px;
}
.fc-ml-40 {
  margin-left: 40px;
}
.fc-mr-40 {
  margin-right: 40px;
}

.fc-m-48 {
  margin: 48px;
}
.fc-mv-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.fc-mh-48 {
  margin-left: 48px;
  margin-right: 48px;
}
.fc-mt-48 {
  margin-top: 48px;
}
.fc-mb-48 {
  margin-bottom: 48px;
}
.fc-ml-48 {
  margin-left: 48px;
}
.fc-mr-48 {
  margin-right: 48px;
}

.fc-m-56 {
  margin: 56px;
}
.fc-mv-56 {
  margin-top: 56px;
  margin-bottom: 56px;
}
.fc-mh-56 {
  margin-left: 56px;
  margin-right: 56px;
}
.fc-mt-56 {
  margin-top: 56px;
}
.fc-mb-56 {
  margin-bottom: 56px;
}
.fc-ml-56 {
  margin-left: 56px;
}
.fc-mr-56 {
  margin-right: 56px;
}

.fc-m-64 {
  margin: 64px;
}
.fc-mv-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}
.fc-mh-64 {
  margin-left: 64px;
  margin-right: 64px;
}
.fc-mt-64 {
  margin-top: 64px;
}
.fc-mb-64 {
  margin-bottom: 64px;
}
.fc-ml-64 {
  margin-left: 64px;
}
.fc-mr-64 {
  margin-right: 64px;
}

.fc-m-80 {
  margin: 80px;
}
.fc-mv-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
.fc-mh-80 {
  margin-left: 80px;
  margin-right: 80px;
}
.fc-mt-80 {
  margin-top: 80px;
}
.fc-mb-80 {
  margin-bottom: 80px;
}
.fc-ml-80 {
  margin-left: 80px;
}
.fc-mr-80 {
  margin-right: 80px;
}

.fc-m-96 {
  margin: 96px;
}
.fc-mv-96 {
  margin-top: 96px;
  margin-bottom: 96px;
}
.fc-mh-96 {
  margin-left: 96px;
  margin-right: 96px;
}
.fc-mt-96 {
  margin-top: 96px;
}
.fc-mb-96 {
  margin-bottom: 96px;
}
.fc-ml-96 {
  margin-left: 96px;
}
.fc-mr-96 {
  margin-right: 96px;
}

.fc-m-120 {
  margin: 120px;
}
.fc-mv-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}
.fc-mh-120 {
  margin-left: 120px;
  margin-right: 120px;
}
.fc-mt-120 {
  margin-top: 120px;
}
.fc-mb-120 {
  margin-bottom: 120px;
}
.fc-ml-120 {
  margin-left: 120px;
}
.fc-mr-120 {
  margin-right: 120px;
}

.fc-m-144 {
  margin: 144px;
}
.fc-mv-144 {
  margin-top: 144px;
  margin-bottom: 144px;
}
.fc-mh-144 {
  margin-left: 144px;
  margin-right: 144px;
}
.fc-mt-144 {
  margin-top: 144px;
}
.fc-mb-144 {
  margin-bottom: 144px;
}
.fc-ml-144 {
  margin-left: 144px;
}
.fc-mr-144 {
  margin-right: 144px;
}

.fc-m-160 {
  margin: 160px;
}
.fc-mv-160 {
  margin-top: 160px;
  margin-bottom: 160px;
}
.fc-mh-160 {
  margin-left: 160px;
  margin-right: 160px;
}
.fc-mt-160 {
  margin-top: 160px;
}
.fc-mb-160 {
  margin-bottom: 160px;
}
.fc-ml-160 {
  margin-left: 160px;
}
.fc-mr-160 {
  margin-right: 160px;
}

/* border */ 
.fc-b-8 {
  border-width: 8px;
}

.fc-hide-i {
  display: none !important;
  opacity:0;
  height: 0;
  width: 0;
}


/* SM MOBILE */

@media screen and (max-width: 576px) {
  /* padding */
  .fc-sm-p-0 {
    padding: 0px;
  }
  .fc-sm-pv-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .fc-sm-ph-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .fc-sm-pt-0 {
    padding-top: 0px;
  }
  .fc-sm-pb-0 {
    padding-bottom: 0px;
  }
  .fc-sm-pl-0 {
    padding-left: 0px;
  }
  .fc-sm-pr-0 {
    padding-right: 0px;
  }

  .fc-sm-p-8 {
    padding: 8px;
  }
  .fc-sm-pv-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .fc-sm-ph-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .fc-sm-pt-8 {
    padding-top: 8px;
  }
  .fc-sm-pb-8 {
    padding-bottom: 8px;
  }
  .fc-sm-pl-8 {
    padding-left: 8px;
  }
  .fc-sm-pr-8 {
    padding-right: 8px;
  }

  .fc-sm-p-16 {
    padding: 16px;
  }
  .fc-sm-pv-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .fc-sm-ph-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .fc-sm-pt-16 {
    padding-top: 16px;
  }
  .fc-sm-pb-16 {
    padding-bottom: 16px;
  }
  .fc-sm-pl-16 {
    padding-left: 16px;
  }
  .fc-sm-pr-16 {
    padding-right: 16px;
  }

  .fc-sm-p-24 {
    padding: 24px;
  }
  .fc-sm-pv-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .fc-sm-ph-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .fc-sm-pt-24 {
    padding-top: 24px;
  }
  .fc-sm-pb-24 {
    padding-bottom: 24px;
  }
  .fc-sm-pl-24 {
    padding-left: 24px;
  }
  .fc-sm-pr-24 {
    padding-right: 24px;
  }

  .fc-sm-p-32 {
    padding: 32px;
  }
  .fc-sm-pv-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .fc-sm-ph-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .fc-sm-pt-32 {
    padding-top: 32px;
  }
  .fc-sm-pb-32 {
    padding-bottom: 32px;
  }
  .fc-sm-pl-32 {
    padding-left: 32px;
  }
  .fc-sm-pr-32 {
    padding-right: 32px;
  }

  .fc-sm-p-40 {
    padding: 40px;
  }
  .fc-sm-pv-40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .fc-sm-ph-40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .fc-sm-pt-40 {
    padding-top: 40px;
  }
  .fc-sm-pb-40 {
    padding-bottom: 40px;
  }
  .fc-sm-pl-40 {
    padding-left: 40px;
  }
  .fc-sm-pr-40 {
    padding-right: 40px;
  }

  .fc-sm-p-48 {
    padding: 48px;
  }
  .fc-sm-pv-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .fc-sm-ph-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .fc-sm-pt-48 {
    padding-top: 48px;
  }
  .fc-sm-pb-48 {
    padding-bottom: 48px;
  }
  .fc-sm-pl-48 {
    padding-left: 48px;
  }
  .fc-sm-pr-48 {
    padding-right: 48px;
  }

  .fc-sm-p-56 {
    padding: 56px;
  }
  .fc-sm-pv-56 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .fc-sm-ph-56 {
    padding-left: 56px;
    padding-right: 56px;
  }
  .fc-sm-pt-56 {
    padding-top: 56px;
  }
  .fc-sm-pb-56 {
    padding-bottom: 56px;
  }
  .fc-sm-pl-56 {
    padding-left: 56px;
  }
  .fc-sm-pr-56 {
    padding-right: 56px;
  }

  .fc-sm-p-64 {
    padding: 64px;
  }
  .fc-sm-pv-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .fc-sm-ph-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .fc-sm-pt-64 {
    padding-top: 64px;
  }
  .fc-sm-pb-64 {
    padding-bottom: 64px;
  }
  .fc-sm-pl-64 {
    padding-left: 64px;
  }
  .fc-sm-pr-64 {
    padding-right: 64px;
  }

  .fc-sm-p-80 {
    padding: 80px;
  }
  .fc-sm-pv-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .fc-sm-ph-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .fc-sm-pt-80 {
    padding-top: 80px;
  }
  .fc-sm-pb-80 {
    padding-bottom: 80px;
  }
  .fc-sm-pl-80 {
    padding-left: 80px;
  }
  .fc-sm-pr-80 {
    padding-right: 80px;
  }

  .fc-sm-p-96 {
    padding: 96px;
  }
  .fc-sm-pv-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .fc-sm-ph-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .fc-sm-pt-96 {
    padding-top: 96px;
  }
  .fc-sm-pb-96 {
    padding-bottom: 96px;
  }
  .fc-sm-pl-96 {
    padding-left: 96px;
  }
  .fc-sm-pr-96 {
    padding-right: 96px;
  }

  .fc-sm-p-120 {
    padding: 120px;
  }
  .fc-sm-pv-120 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .fc-sm-ph-120 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .fc-sm-pt-120 {
    padding-top: 120px;
  }
  .fc-sm-pb-120 {
    padding-bottom: 120px;
  }
  .fc-sm-pl-120 {
    padding-left: 120px;
  }
  .fc-sm-pr-120 {
    padding-right: 120px;
  }

  .fc-sm-p-144 {
    padding: 144px;
  }
  .fc-sm-pv-144 {
    padding-top: 144px;
    padding-bottom: 144px;
  }
  .fc-sm-ph-144 {
    padding-left: 144px;
    padding-right: 144px;
  }
  .fc-sm-pt-144 {
    padding-top: 144px;
  }
  .fc-sm-pb-144 {
    padding-bottom: 144px;
  }
  .fc-sm-pl-144 {
    padding-left: 144px;
  }
  .fc-sm-pr-144 {
    padding-right: 144px;
  }

  .fc-sm-p-160 {
    padding: 160px;
  }
  .fc-sm-pv-160 {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .fc-sm-ph-160 {
    padding-left: 160px;
    padding-right: 160px;
  }
  .fc-sm-pt-160 {
    padding-top: 160px;
  }
  .fc-sm-pb-160 {
    padding-bottom: 160px;
  }
  .fc-sm-pl-160 {
    padding-left: 160px;
  }
  .fc-sm-pr-160 {
    padding-right: 160px;
  }

  /* margin */
  .fc-sm-m-8 {
    margin: 8px;
  }
  .fc-sm-mv-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .fc-sm-mh-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .fc-sm-mt-8 {
    margin-top: 8px;
  }
  .fc-sm-mb-8 {
    margin-bottom: 8px;
  }
  .fc-sm-ml-8 {
    margin-left: 8px;
  }
  .fc-sm-mr-8 {
    margin-right: 8px;
  }

  .fc-sm-m-16 {
    margin: 16px;
  }
  .fc-sm-mv-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .fc-sm-mh-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .fc-sm-mt-16 {
    margin-top: 16px;
  }
  .fc-sm-mb-16 {
    margin-bottom: 16px;
  }
  .fc-sm-ml-16 {
    margin-left: 16px;
  }
  .fc-sm-mr-16 {
    margin-right: 16px;
  }

  .fc-sm-m-24 {
    margin: 24px;
  }
  .fc-sm-mv-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .fc-sm-mh-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .fc-sm-mt-24 {
    margin-top: 24px;
  }
  .fc-sm-mb-24 {
    margin-bottom: 24px;
  }
  .fc-sm-ml-24 {
    margin-left: 24px;
  }
  .fc-sm-mr-24 {
    margin-right: 24px;
  }

  .fc-sm-m-32 {
    margin: 32px;
  }
  .fc-sm-mv-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .fc-sm-mh-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .fc-sm-mt-32 {
    margin-top: 32px;
  }
  .fc-sm-mb-32 {
    margin-bottom: 32px;
  }
  .fc-sm-ml-32 {
    margin-left: 32px;
  }
  .fc-sm-mr-32 {
    margin-right: 32px;
  }

  .fc-sm-m-40 {
    margin: 40px;
  }
  .fc-sm-mv-40 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .fc-sm-mh-40 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .fc-sm-mt-40 {
    margin-top: 40px;
  }
  .fc-sm-mb-40 {
    margin-bottom: 40px;
  }
  .fc-sm-ml-40 {
    margin-left: 40px;
  }
  .fc-sm-mr-40 {
    margin-right: 40px;
  }

  .fc-sm-m-48 {
    margin: 48px;
  }
  .fc-sm-mv-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .fc-sm-mh-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .fc-sm-mt-48 {
    margin-top: 48px;
  }
  .fc-sm-mb-48 {
    margin-bottom: 48px;
  }
  .fc-sm-ml-48 {
    margin-left: 48px;
  }
  .fc-sm-mr-48 {
    margin-right: 48px;
  }

  .fc-sm-m-56 {
    margin: 56px;
  }
  .fc-sm-mv-56 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .fc-sm-mh-56 {
    margin-left: 56px;
    margin-right: 56px;
  }
  .fc-sm-mt-56 {
    margin-top: 56px;
  }
  .fc-sm-mb-56 {
    margin-bottom: 56px;
  }
  .fc-sm-ml-56 {
    margin-left: 56px;
  }
  .fc-sm-mr-56 {
    margin-right: 56px;
  }

  .fc-sm-m-64 {
    margin: 64px;
  }
  .fc-sm-mv-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .fc-sm-mh-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .fc-sm-mt-64 {
    margin-top: 64px;
  }
  .fc-sm-mb-64 {
    margin-bottom: 64px;
  }
  .fc-sm-ml-64 {
    margin-left: 64px;
  }
  .fc-sm-mr-64 {
    margin-right: 64px;
  }

  .fc-sm-m-80 {
    margin: 80px;
  }
  .fc-sm-mv-80 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .fc-sm-mh-80 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .fc-sm-mt-80 {
    margin-top: 80px;
  }
  .fc-sm-mb-80 {
    margin-bottom: 80px;
  }
  .fc-sm-ml-80 {
    margin-left: 80px;
  }
  .fc-sm-mr-80 {
    margin-right: 80px;
  }

  .fc-sm-m-96 {
    margin: 96px;
  }
  .fc-sm-mv-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .fc-sm-mh-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .fc-sm-mt-96 {
    margin-top: 96px;
  }
  .fc-sm-mb-96 {
    margin-bottom: 96px;
  }
  .fc-sm-ml-96 {
    margin-left: 96px;
  }
  .fc-sm-mr-96 {
    margin-right: 96px;
  }

  .fc-sm-m-120 {
    margin: 120px;
  }
  .fc-sm-mv-120 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .fc-sm-mh-120 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .fc-sm-mt-120 {
    margin-top: 120px;
  }
  .fc-sm-mb-120 {
    margin-bottom: 120px;
  }
  .fc-sm-ml-120 {
    margin-left: 120px;
  }
  .fc-sm-mr-120 {
    margin-right: 120px;
  }

  .fc-sm-m-144 {
    margin: 144px;
  }
  .fc-sm-mv-144 {
    margin-top: 144px;
    margin-bottom: 144px;
  }
  .fc-sm-mh-144 {
    margin-left: 144px;
    margin-right: 144px;
  }
  .fc-sm-mt-144 {
    margin-top: 144px;
  }
  .fc-sm-mb-144 {
    margin-bottom: 144px;
  }
  .fc-sm-ml-144 {
    margin-left: 144px;
  }
  .fc-sm-mr-144 {
    margin-right: 144px;
  }

  .fc-sm-m-160 {
    margin: 160px;
  }
  .fc-sm-mv-160 {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  .fc-sm-mh-160 {
    margin-left: 160px;
    margin-right: 160px;
  }
  .fc-sm-mt-160 {
    margin-top: 160px;
  }
  .fc-sm-mb-160 {
    margin-bottom: 160px;
  }
  .fc-sm-ml-160 {
    margin-left: 160px;
  }
  .fc-sm-mr-160 {
    margin-right: 160px;
  }
}

/* border */ 
.fc-b-8 {
  border-width: 8px;
}

/* reusable classes */
.fc-title {

}
.fc-title__blue {
  font-size: 10px;
  text-transform: uppercase;
  color: #0063ed;
}
.fc-clock {
  width: 14px;
  vertical-align: sub;
}
.fc-button {
  transition: color .4s, background-color .4s;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 32px;
  text-decoration: none;
  display: block;
  margin: auto;
  border: none;
}
.fc-button.fc-color-alarm-true {
  background-color: #FC4631;
  color: #fff;
  width: 100%;
  border: none;
}
.fc-button.fc-color-alarm-true:hover,
.fc-button.fc-color-alarm-true:disabled {
  background-color: #dd0000;
  border: none;
}
.fc-button.fc-large {
  font-size: 24px;
  width: 80%;
  margin: auto;
  display: block;
}
.fc-button.fc-blue {
  color: #fff;
  background-color: #0063ec;
}
.fc-button.fc-blue:hover {
  background-color: #004fbd;
}
.fc-spinner-loading {
  height: 14px;
  width: auto;
  animation: infinitespinning 2s linear infinite;
}

@keyframes infinitespinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

